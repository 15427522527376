import * as React from 'react';
import '../styles/index.scss';

const ThankYouPage = () => {
  return (
    <main>
      <title>Bianca &amp; Mihai - 17 Iulie 2022</title>
      <section  className="flex vertical-middle">
        <div className="wrapper text-center">
          <h1>Mulțumim, vă asteptăm cu drag!</h1>
          <p className="anchor-button">
            <a
              href="/?a=locatie"
              className="button"
            >
              Acasă / Direcții
            </a>
          </p>
        </div>
      </section>
    </main>
  );
}

export default ThankYouPage;
